<template>
  <v-main class="natural lighten-3">
    <v-container fluid>
      <v-row >
        <v-col cols="12" v-for="(item,index) in items" :key="index">
       <RecordCard :name="item.arrResult[0].name_th" :cid="item.arrResult[0].id_number" :timestamp="item.arrResult[0].createDate" mode='savedraft' @edit="editData(item)" :formTypeName="item.arrResult[0].formTypeName"/>
        </v-col>
          <!-- <v-col cols="12">
         <v-btn  x-large height="58"  block color="primary" @click="pushDraftData">ส่งแบบร่างทั้งหมด</v-btn>
        </v-col> -->
        <!-- <v-col cols="12">
       <RecordCard mode='savedraft'/>
        </v-col> -->
      </v-row>
    </v-container>
     <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import RecordCard from "@/components/recorddaily/RecordCard";
import { mapFields } from "vuex-map-fields";
import { postAwaitFormResult } from "@/api/";

export default {
  components: {
    RecordCard,
  },
  data() {
    return {
      items: [],
      role: null,
      loading: false
    }
  },
  computed: {
    ...mapFields(["formDraft"])
  },
  methods: {
    fetchData() {
      try{
        this.loading = true
        this.role = this.$offlineStorage.get("role");
        let draft = this.$offlineStorage.get("draft");
        if(draft){
        this.items = draft.filter(item=> item[0]===this.role.uuid.mobile).map(item=> item[1])
        }
      }catch(ex){
        console.log(ex)
      }finally{
        this.loading = false
      }
    },
    editData(item){
        console.log(item)
        this.$offlineStorage.set('currentForm',item)
        this.$router.push({ name: 'editform' })

    },
    async pushDraftData() {
      if (navigator.onLine) {
        let offlineItem = this.$offlineStorage.get("draft");
        if(offlineItem)
        {
        try {
          for (let i = 0; i < offlineItem.length; i++) {
            let data = offlineItem[i][1] 
            try{
            const message = await postAwaitFormResult({ data })
           if (message.data.code === 1) {
                  offlineItem[i][1].status = "success";
                }else{
                  offlineItem[i][1].status = "fail";
                }
           
            }catch(err){
            offlineItem[i][1].status = "fail";
            }
          }
        } finally {
           for (let i = 0; i < offlineItem.length; i++) {
             if(offlineItem[i][1].status === 'success'){
               offlineItem.splice(i,1)
             }

           }
           this.$offlineStorage.set("draft",offlineItem);
           this.fetchData()
        }
        }

      }
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
   }
};
</script>

<style lang="scss" scoped>
.v-btn {
  border-radius: 6px;
}
</style>